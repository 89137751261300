import moment from "moment";
import Adoration from "../assets/images/adoration.svg";
import bigSmile from "../assets/images/big-smile-emoji-emoticon-emoticons-emotion-expression-svgrepo-com.svg";
import sadSmile from "../assets/images/sad.svg";
const weatherIconURL = "https://openweathermap.org/img/wn/";

const HelperService = {
  getFormatedDateForDetail(dt: any, format?: any) {
    var stillUtc = moment.utc(dt).toDate();
    if (dt) {
      if (format) {
        return moment(stillUtc).local().format(format);
      } else {
        return moment(stillUtc)
          .local()
          .format(
            localStorage.getItem("date_format")
              ? localStorage.getItem("date_format") + " hh:mm A"
              : "DD-MM-YYYY  hh:mm A"
          );
      }
    }
  },

  validateEmail(email: string) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  },
  getIconForWeather(icon: string) {
    if (icon !== "") return `${weatherIconURL}${icon}.png`;
  },
  getPM25Object(progressValue: number, paramter: string) {
    if (progressValue < 10) {
      return {
        label: "Too slow",
        badgeClass: "verybad",
        emoji: sadSmile,
        parameter: paramter,
        value: progressValue,
      };
    } else if (progressValue <= 30) {
      return {
        label: "Very Slow",
        badgeClass: "verybad",
        emoji: sadSmile,
        parameter: paramter,
        value: progressValue,
      };
    } else if (progressValue <= 50) {
      return {
        label: "Slow",
        badgeClass: "bad",
        emoji: sadSmile,
        parameter: paramter,
        value: progressValue,
      };
    } else if (progressValue <= 70) {
      return {
        label: "Normal",
        badgeClass: "average",
        emoji: bigSmile,
        parameter: paramter,
        value: progressValue,
      };
    } else if (progressValue <= 90) {
      return {
        label: "Fast",
        badgeClass: "average",
        emoji: Adoration,
        parameter: paramter,
        value: progressValue,
      };
    } else {
      return {
        label: "Very Fast",
        badgeClass: "verygood",
        emoji: Adoration,
        parameter: paramter,
        value: progressValue,
      };
    }
  },

  removeUnderScore(str: any) {
    if ((str !== "" || str !== undefined) && str.includes("_")) {
      return str.replace(/_/g, " ").toUpperCase();
    } else {
      return str.toUpperCase();
    }
  },
  toProperCase(str: string) {
    if (!str) {
      return "";
    }
    const pattern = /_/gi;
    const replacement = " ";
    const result = str.replaceAll(pattern, replacement);

    return result.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },

  formatText(str: any) {
    if ((str !== "" || str !== undefined) && str.includes("_")) {
      return str.replace(/_/g, " ").toLowerCase();
    } else {
      return str.toLowerCase();
    }
  },
  formatNumber(num: any) {
    let convertedNum = Number(num);
    if (convertedNum % 1 !== 0) {
      return convertedNum.toFixed(2);
    } else {
      return convertedNum;
    }
  },
  formatLabel(label: any) {
    switch (label) {
      case "PM2.5":
        return "pm2_5";
      case "PM10":
        return "pm10";
      case "NH3":
        return "nh3";
      case "O2":
        return "o2";
      case "Humidity":
        return "rh";
      case "TVOC":
        return "tvoc";

      case "CO2":
        return "co2";
      case "O3":
        return "O3";
      case "CO":
        return "co";
      case "NO2":
        return "no2";

      default:
        return label.toLowerCase();
    }
  },
};

export default HelperService;
