import axios from "axios";
import { toast } from "react-toastify";
import WebService from "../../Services/WebService";
const BASE_URL = "https://api.openweathermap.org/";
const GoogleAPI = "https://maps.googleapis.com/maps/api/";
const apiKey = "74fc72f9df6e931a1cacc472c29b37b3";
const googleAPIKey = "AIzaSyBmEnxg2Ohtu2_BkSJiG-snKy8tqd2jt-g";
export function getWeatherService(lattitude:any,longitude:any) {
  const lat = lattitude;
  const lon =longitude;
  return new Promise((resolve, reject) => {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    axios
      .get(
        `${BASE_URL}data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apiKey}`,
        {
          headers: headers,
        }
      )
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {});
  });
}
export function getMachineLocation(lattitude:any,longitude:any) {
  const lat = lattitude;
  const lon = longitude;

    // const lat = '18.5642';
  // const lon =  '73.7769'
  return new Promise((resolve, reject) => {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    axios
      .get(
        `${GoogleAPI}geocode/json?latlng=${lat},${lon}&key=${googleAPIKey}`,

        {
          headers: headers,
        }
      )
      .then((response: any) => {
        resolve(response?.data?.results);
      })
      .catch((error: any) => {});
  });
}
export async function getIndoorDataAPI(stationId: string) {
  return await WebService.getAPI({
    action: `iot/dashboard?stationId=${stationId}`,
    body: null,
  })
    .then((res: any) => {
      return res;
    })
    .catch((error: any) => {
      console.log("error", error);
      toast.error(error?.response?.data?.message);
      return null;
    });
}
